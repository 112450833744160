.formContentBox[data-v-4d2ac92c] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-4d2ac92c] {
  width: 100%;
  overflow: auto;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 12px;
}
.formTopic[data-v-4d2ac92c] {
  margin-bottom: 12px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}